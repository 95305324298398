import router from "@/router/index.js";
import Vue from "vue";
import rate from "@/components/Rate/Rate.vue";
import moment from "moment";
const getPercent = function (val) {
  return (val * 100).toFixed(2) + "%";
};
const getColor = function (val) {
  if (val > 0) {
    return "color:red;";
  } else if (val === 0) {
    return "";
  } else {
    return "color:green;";
  }
};
const getDateColor = function (val) {
  if (val) {
    let today = moment().format("YYYY-MM-DD");

    if (val == today) {
      return {
        textDecoration: "underline",
      };
    } else {
      return {
        textDecoration: "none",
      };
    }
  } else {
    return {
      textDecoration: "none",
    };
  }
};
const getDirectionColor = function (val) {
  if (val == 1) {
    return "color:red;";
  } else {
    return "color:green;";
  }
};
//打开个股详情
const openStockDetail = function (val) {
  router.push({ name: "个股详情", params: { id: val } });
};
//打开标签
const openTagDetail = function (id) {
  //console.log(type,id);
  router.push({ name: "标签库-详情", params: { id: id } });
};
//股票评分
const openRate = function (id, name, _this, factorInfo = "") {
  Vue.prototype.$layer.iframe({
    content: {
      content: rate, //传递的组件对象
      parent: _this, //当前的vue对象
      data: {
        iframeData: {
          id: id,
          factorInfo: factorInfo,
        },
      },
    },
    area: ["1280px", "720px"],
    title: "股票评分--" + name,
    shade: false,
    maxmin: true, //开启最大化最小化
    resize: false, //是否允许拉伸，默认是不允许
  });
};
const formatValue = (value) => {
  if (typeof value === "undefined") {
    return "";
  }
  value = formatPrefix(value);
  value = formatPercent(value);
  return value;
};
const formatPrefix = (value) => {
  if (value == "--") {
    return value;
  }
  value = parseFloat(value).toFixed(2);
  value = value > 0 ? "+" + value : value;

  return value;
};
const formatPercent = (value) => {
  if (value == "--") {
    return value;
  }
  value = value.toString();
  if (!value.includes("%")) {
    value = value + "%";
  }
  return value;
};

export default {
  formatValue,
  getPercent,
  getColor,
  getDateColor,
  getDirectionColor,
  openStockDetail,
  openTagDetail,
  openRate,
};
