import Tools from "../tools";
import axios from "axios";

// 普通请求
export const xhr = function (config) {
  let promise, instance;

  instance = axios({
    ...config,
    timeout: 600000,
  });

  return instance;
};
//fetch请求
export const fetch = function (config) {
  let promise, instance;
  instance = axios({
    ...config,
    timeout: 600000,
  });

  return instance;
};
// 上传
export const upload = function (config) {
  // let promise, instance;
  // config.method = 'post';
  // instance = axios({
  //   ...config,
  //   timeout: 60000,
  // });

  // return instance;

  let promise, instance;

  // promise = new Promise((resolve, reject) => {
  //   instance = uni.uploadFile({
  //     ...config,
  //     success: (res) => {
  //       res.data = Tools.toJSON(res.data);

  //       resolve(res);
  //     },
  //     fail: reject
  //   });

  // });

  console.log(config);
  let param = new FormData(); // 创建form对象
  //处理文件 config.data.file

  param.append("file", config.data.file); // 通过append向form对象添加数据

  for (let val in config.data) {
    if (val != "file") {
      param.append(val, config.data[val]);
    }
  }

  //param.append("chunk", "别的数据"); // 添加form表单中其他数据
  promise = new Promise((resolve, reject) => {
    instance = axios
      .post(
        config.url,
        param,
        // 第1个参数 url 第二参数 data数据，第三个是配置渲染，
        // onUploadProgress 当上传进度是
        {
          // onUploadProgress: (e) => {
          //   that.pre = Math.floor((e.loaded / e.total) * 100);
          //   // e.loaded 已经上传的字节数据，e.total 字节数据  转换为1-100的比例值 赋值个pre
          // },
          headers: {
            Authorization: config.headers["Authorization"],
          },
        }
      )
      .then((res) => {
        resolve(res);
      });
  });

  promise.__proto__.example = instance; // 使用 `Object.setProrotypeOf` 会修改自己的隐性原型，把 `Promise` 重新指向成 `Object`

  return promise;
};

function addTask(config, instance) {
  const taskList = [
    "onProgressUpdate",
    "onHeadersReceived",
    "offProgressUpdate",
    "offHeadersReceived",
  ];

  taskList.forEach((task) => {
    let callback = config[task];

    typeof callback === "function" && instance[task](callback);
  });
}
