// Imports
import Vue from "vue";
import Router from "vue-router";
import { layout, route } from "@/util/routes";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
  routes: [
    // {
    // 	path: '*',
    // 	redirect: '/error',
    // 	name: 'error'
    // },

    {
      path: "/",
      redirect: "/home",
      name: "defaultRoute",
    },

    // layout('Page', [
    //   route('帐号登录', {default:'Login'}, 'login'),
    // ]),
    layout("AuthIllustrationLayout", [
      route("登录", { default: "Login" }, "login"),
      route("更改密码", { default: "ChangePwd" }, "ChangePwd"),
    ]),

    layout("DashboardLayout", [
      route("主页", { default: "Dashboard/Dashboard" }, "home", {
        keepAlive: true,
        permission: "router.dashboard",
      }),

      route("股票池", { default: "StockPool" }, "StockPool", {
        keepAlive: true,
        permission: "router.stockPool",
      }),
      route("个股详情", { default: "StockDetail" }, "StockDetail/:id", {
        keepAlive: false,
        permission: "router.stockDetail",
        canBack: true,
      }),

      route("组合", { default: "Portfolio" }, "Portfolio", {
        keepAlive: false,
        permission: "router.portfolio",
      }),
      route("组合详情", { default: "PortfolioDetail" }, "PortfolioDetail/:id", {
        keepAlive: true,
        permission: "router.portfolioDetail",
        canBack: true,
      }),

      route("标签库-详情", { default: "TagStore" }, "TagStore/:id", {
        keepAlive: false,
        permission: "router.tagStore",
        canBack: true,
      }),
      route("标签库", { default: "TagStore" }, "TagStore", {
        keepAlive: false,
        permission: "router.tagStore",
      }),

      route("资料库", { default: "FileStore" }, "FileStore", {
        keepAlive: false,
        permission: "router.fileStore",
      }),
      route("复盘-详情", { default: "Review" }, "Review/:id", {
        keepAlive: false,
        permission: "router.review",
      }),
      route("复盘", { default: "Review" }, "Review", {
        keepAlive: true,
        permission: "router.review",
      }),
      route("评分历史", { default: "RateHistory" }, "RateHistory", {
        keepAlive: true,
        permission: "router.rateHistory",
      }),

      route(
        "公告汇总",
        { default: "AnnouncementSummary" },
        "AnnouncementSummary",
        { permission: "router.announcementSummary", keepAlive: false }
      ),
      route(
        "共振对比-详情",
        { default: "QuantitativeAnalysis/ResonanceContrast" },
        "ResonanceContrast/:id",
        {
          keepAlive: false,
          permission: "router.resonanceContrast",
        }
      ),
      route(
        "共振对比",
        { default: "QuantitativeAnalysis/ResonanceContrast" },
        "ResonanceContrast",
        { permission: "router.resonanceContrast", keepAlive: false }
      ),
      route(
        "波动率曲面",
        { default: "QuantitativeAnalysis/VolatilitySurfaces" },
        "VolatilitySurfaces",
        { permission: "router.volatilitySurfaces", keepAlive: false }
      ),
      route(
        "增发与解禁",
        { default: "AdditionalAndLifting" },
        "AdditionalAndLifting",
        { permission: "router.additionalAndLifting", keepAlive: false }
      ),

      route("系统管理", { default: "Manage" }, "Manage", {
        permission: "router.manage",
        keepAlive: false,
      }),
      route("推荐统计", { default: "Recommend" }, "Recommend", {
        permission: "router.recommend",
        keepAlive: false,
      }),
      route("笔记", { default: "NoteIndex" }, "NoteIndex/:id?", {
        permission: "appBar.note",
        canBack: true,
        keepAlive: true,
      }),

      route("调研", { default: "Research" }, "Research", {
        permission: "router.research",
        keepAlive: false,
      }),

      route("股票推荐", { default: "StockRecommend" }, "StockRecommend", {
        permission: "router.stockRecommend",
        canBack: true,
        keepAlive: false,
      }),

      route("实验室", { default: "Ai" }, "Ai", {
        permission: "router.ai",
        canBack: true,
        keepAlive: false,
      }),

      route("信息中心", { default: "InfoCenter" }, "InfoCenter", {
        permission: "router.InfoCenter",
        canBack: true,
        keepAlive: false,
      }),

      route("投资日历", { default: "TimeLine" }, "TimeLine", {
        permission: "router.TimeLine",
        canBack: true,
        keepAlive: false,
      }),
      route("添加日历", { default: "TimeLineAdd" }, "TimeLineAdd", {
        permission: "timeLine.edit",
        canBack: true,
        keepAlive: false,
      }),
    ]),

    layout("PrintLayout", [
      route("打印", { default: "Print" }, "print"),
      route("打印页面", { default: "PrintHtml" }, "printHtml"),
      route("打印标签", { default: "PrintTag" }, "printTag"),
      route("打印MD", { default: "PrintMD" }, "printMD"),
    ]),
    layout("QuantLayout", [
      route("量化", { default: "Quant" }, "Quant", {
        permission: "router.quant",
        canBack: true,
        keepAlive: false,
      }),
    ]),
  ],
});

router.beforeResolve((to, from, next) => {
  let showAlert = () => {
    Vue.swal({
      icon: "error",
      title: "提示",
      text: "抱歉您没有该权限",
    });
  };
  //console.log(to.meta.permission,Vue.prototype.userStore);
  if (
    to.name != "登录" &&
    to.name != "更改密码" &&
    to.name != "打印" &&
    to.name != "打印标签" &&
    to.name != "打印页面" &&
    to.name != "打印MD"
  ) {
    if (!Vue.prototype.userStore) {
      return next({ name: "登录" });
    }

    //console.log(to.meta.permission,Vue.prototype.$commonHelpers.getPermissions(to.meta.permission));
    if (!Vue.prototype.$commonHelpers.getPermissions(to.meta.permission)) {
      showAlert();
      //return router.back();
      return next({ name: "登录" });
      //return false;
    }
  }

  return next();
});

export default router;
