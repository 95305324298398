<template>
  <div style="height: 100%; width: 100%; overflow: hidden" class="mt-1">
    <div class="chat-container">
      <div class="chat-area">
        <div class="chat-header" v-if="messages.length == 0">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="#1a73e8">
            <path
              d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-13h2v6h-2zm0 8h2v2h-2z"
            />
          </svg>
          <span class="chat-title">新对话</span>
        </div>

        <div class="message-container" ref="messageContainer">
          <div
            class="message"
            :class="message.role == 'user' ? 'user-message' : 'bot-message'"
            v-for="(message, index) in messages"
            :key="index"
          >
            <!-- 移除多余的 div 嵌套 -->
            <template v-if="message.content">
              <div class="thinking-preview" v-if="message.reasoning">
                <!-- <div class="thinking-header">思考过程</div> -->
                {{ message.reasoning }}
                <!-- <div v-html="renderMarkdown(message.reasoning)"></div> -->
              </div>
              <div
                class="final-result"
                v-html="renderMarkdown(message.content)"
              ></div>
            </template>
            <div v-else class="thinking-message">
              <div class="thinking-dot"></div>
              {{ message.reasoning }}
              <!-- <div v-html="renderMarkdown(message.reasoning)"></div> -->
            </div>
          </div>
        </div>

        <div class="input-area">
          <div class="input-wrapper">
            <input
              type="text"
              v-model="inputText"
              id="messageInput"
              placeholder="给 DeepSeek 发送消息 "
              autocomplete="off"
              :disabled="isProcessing"
              @keyup.enter="!isProcessing && sendMessage()"
            />

            <button @click="sendMessage" :disabled="isProcessing">发送</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import User from "@/api/user.js";
import { marked } from "marked";
import DOMPurify from "dompurify";
export default {
  name: "Chat",
  components: {},
  props: {
    pageHeight: {
      type: Number,
      default: 500,
    },
  },
  data() {
    return {
      user: {
        id: "1",
        displayName: "Knight",
        avatar: require("@/assets/img/user/user.png"),
      },
      conversationId: null,
      parentMessageId: null,

      conversationCounter: 0,
      modelSelect: "1",
      messages: [],
      inputText: "",
      newChat: true,
      isProcessing: false,
    };
  },
  mounted() {},
  // 在script部分修改methods
  methods: {
    renderMarkdown(rawText) {
      const renderer = new marked.Renderer();
      // 添加链接外部打开支持
      renderer.link = (href, title, text) => {
        const safeHref = String(href.href || "").trim() || "#";
        const safeText = String(href.text || safeHref).trim();
        return `<a href="${safeHref}"  rel="noopener noreferrer" class="external-link" target="_blank">${safeText}</a>`;
      };

      return DOMPurify.sanitize(marked.parse(rawText || "", { renderer }), {
        ADD_ATTR: ["target"], // 允许target属性
        ADD_URI_SAFE_ATTR: ["target"], // 将target加入URI安全属性
        FORBID_ATTR: ["style", "onclick"], // 明确禁止危险属性
      });
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.messageContainer;
        if (container) {
          container.scrollTop = container.scrollHeight;
        }
      });
    },

    async sendMessage() {
      if (!this.inputText.trim() || this.isProcessing) return;
      this.isProcessing = true;
      this.messages.push({
        role: "user",
        content: this.inputText,
      });
      this.inputText = "";
      const api = User.getApi() + "/api/ai/chat";
      console.log(api);
      let currentMessage = [];
      this.messages.forEach((message) => {
        currentMessage.push({ role: message.role, content: message.content });
      });
      const response = await fetch(api, {
        method: "POST",
        headers: {
          Authorization: this.userStore.token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          messages: currentMessage,
        }),
      });
      //读取deepseek流式数据，每次有数据就更新一次
      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
      let buffer = "";
      let assistantMessage = {
        role: "assistant",
        reasoning: "", // 新增思考过程字段
        content: "", // 最终结果字段
      };

      try {
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          buffer += decoder.decode(value, { stream: true });

          const events = buffer.split(/\n\n/);
          buffer = events.pop() || "";

          for (const event of events) {
            const chunks = event
              .split("\n")
              .filter((line) => line.startsWith("data: "));

            for (const chunk of chunks) {
              try {
                const jsonStr = chunk.replace(/^data: /, "");
                if (jsonStr === "[DONE]") continue;

                const data = JSON.parse(jsonStr);
                const reasoning =
                  data.choices[0]?.delta?.reasoning_content || "";
                const result = data.choices[0]?.delta?.content || "";

                // 分别处理思考过程和最终结果
                if (reasoning) {
                  assistantMessage.reasoning += reasoning;
                  if (
                    !this.messages.some(
                      (m) => m.role === "assistant" && m === assistantMessage
                    )
                  ) {
                    this.messages.push(assistantMessage);
                  }
                  this.scrollToBottom(); // 新增：思考内容更新时滚动
                }

                if (result) {
                  assistantMessage.content += result;
                  // 移除清空思考内容的代码
                  // assistantMessage.reasoning = "";
                  this.scrollToBottom();
                }

                this.$forceUpdate();
              } catch (e) {
                console.error("解析错误:", e);
              }
            }
          }
        }
      } finally {
        this.isProcessing = false;
        reader.releaseLock();
        this.scrollToBottom();
      }
    },
  },
};
</script>
<style scoped>
body {
  overflow: hidden;
}

@keyframes dotWave {
  0%,
  100% {
    transform: translateY(0);
    opacity: 0.8;
  }
  50% {
    transform: translateY(-3px);
    opacity: 1;
  }
}

@keyframes typing {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}
.chat-container {
  width: 100%;
  height: 100%;
  background: #fafafa;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", sans-serif;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.sidebar {
  width: 280px;
  background: #ffffff;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.chat-area {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative; /* 新增定位上下文 */
  overflow: hidden;
}

.message-container {
  flex: 1;
  overflow-y: auto;
  padding: 24px;
  padding-bottom: 80px; /* 添加底部间距避免内容遮挡 */
  scroll-behavior: smooth;
}

.input-area {
  position: sticky;
  bottom: 0;
  background: white;
  padding: 16px 24px;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 -2px 12px rgba(0, 0, 0, 0.03);
  z-index: 1;
}

.chat-header {
  padding: 16px 24px;
  background: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  gap: 12px;
}

.chat-title {
  font-size: 14px;
  font-weight: 500;
  color: #1a1a1a;
}

.message-container {
  flex: 1;
  overflow-y: auto;
  padding: 24px;
}

.message {
  max-width: 76%;
  margin: 12px 0;
  padding: 12px 16px;
  border-radius: 16px;
  font-size: 14px;
  line-height: 1.5;
  position: relative;
  animation: messageAppear 0.2s ease-out;
}

.user-message {
  background: #1a73e8;
  color: white;
  margin-left: auto;
  border-radius: 16px 16px 4px 16px;
}

.bot-message {
  background: #ffffff;
  color: #1a1a1a;
  margin-right: auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.03);
  border-radius: 16px 16px 16px 4px;
  /* white-space: pre-line; */
}

.input-area {
  padding: 16px 24px;
  background: #ffffff;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.input-wrapper {
  display: flex;
  gap: 12px;
  background: rgba(0, 0, 0, 0.03);
  border-radius: 12px;
  padding: 8px;
}

#messageInput {
  flex: 1;
  border: none;
  background: transparent;
  padding: 8px 12px;
  font-size: 14px;
  outline: none;
}

button {
  background: #1a73e8;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 8px 20px;
  cursor: pointer;
  transition: opacity 0.2s;
}

button:hover {
  opacity: 0.9;
}

@keyframes messageAppear {
  from {
    transform: translateY(10px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
/*滚动条整体部分*/
.message-container::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/*滚动条的轨道*/
.message-container::-webkit-scrollbar-track {
  background-color: #ffffff;
}

/*滚动条里面的小方块，能向上向下移动*/
.message-container::-webkit-scrollbar-thumb {
  background-color: #bfbfbf;
  border-radius: 5px;
  border: 1px solid #f1f1f1;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.message-container::-webkit-scrollbar-thumb:hover {
  background-color: #a8a8a8;
}

.message-container::-webkit-scrollbar-thumb:active {
  background-color: #787878;
}

/*边角，即两个滚动条的交汇处*/
.message-container::-webkit-scrollbar-corner {
  background-color: #ffffff;
}
.message-container {
  /* 移除 overflow-anchor: none; */
  overflow-y: auto;
}
.thinking-preview {
  background: #f5f5f5;
  padding: 12px 20px;
  border-radius: 16px 16px 16px 4px;
  margin: 12px 0;
  display: inline-flex;
  gap: 8px;
  align-items: center;
  animation: messageAppear 0.2s ease-out;
  font-size: 0.8em;
}

.thinking-stage {
  padding: 4px 0;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.95em;
  color: #666;
  transition: opacity 0.3s;
}

.stage-bullet {
  width: 6px;
  height: 6px;
  background: #1a73e8;
  border-radius: 50%;
  opacity: 0.5;
}

.typing-cursor {
  animation: blink 1s infinite;
  color: #1a73e8;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
.thinking-message {
  background: #f5f5f5;
  padding: 12px 20px;
  border-radius: 16px 16px 16px 4px;
  margin: 12px 0;
  display: inline-flex;
  gap: 8px;
  align-items: center;
  animation: messageAppear 0.2s ease-out;
  font-size: 0.8em;
}

.thinking-dot {
  width: 8px;
  height: 8px;
  background: #d0d0d0;
  border-radius: 50%;
  animation: dotWave 1.4s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
.thinking-header {
  font-size: 12px;
  color: #1b0ed1;
  margin-bottom: 4px;
}
#messageInput:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
.final-result {
  /* 新增markdown样式 */
  code {
    background: #f3f4f6;
    padding: 2px 4px;
    border-radius: 4px;
    font-size: 0.9em;
  }
  pre {
    background: #f8f9fa;
    padding: 12px;
    border-radius: 8px;
    overflow-x: auto;
    code {
      background: none;
      padding: 0;
    }
  }
  blockquote {
    border-left: 4px solid #ddd;
    padding-left: 12px;
    color: #666;
    margin: 8px 0;
  }
  ul,
  ol {
    padding-left: 24px;
    margin: 8px 0;
  }
  li {
    margin: 4px 0;
  }
}
</style>
